<template>
  <YsPagination ref="node" :total="total">
    <template #header>
      <el-input v-model="uname" placeholder="请输入用户姓名查找" clearable />
      <el-select v-model="status" placeholder="请选择托管状态">
        <el-option label="全部" value="" />
        <el-option label="待付款" value="0" />
        <el-option label="托管中" value="1" />
        <el-option label="已取消" value="2" />
      
      </el-select>
      <el-button type="primary" @click.prevent="query">查询</el-button>
    </template>
    <el-table :data="userlist" height="100%" style="width: 100%;" border>
      <el-table-column label="序号" type="index" width="80" fixed />
      <el-table-column label="订单编码" prop="refId" width="250" fixed>
      </el-table-column>
      <el-table-column label="商品名称" prop="goodsName" width="130" fixed>
      </el-table-column>
      <el-table-column label="用户名称" prop="uname" width="100" fixed>
      </el-table-column>
      <el-table-column label="商品价格" prop="price" width="100">
        <!-- <template v-slot="{row}">
          {{ (row.price / 100).toFixed(2) }}
        </template> -->
      </el-table-column>
      <el-table-column label="托管数量" prop="stockCount" width="120">
      </el-table-column>
      <el-table-column label="已售数量" prop="soldCount" width="120">
      </el-table-column>
      <el-table-column label="已付数量" prop="settledCount" width="120">
      </el-table-column>
      <el-table-column label="托管日期" prop="stockDate" width="180">
        <template #default="scope">
          {{ scope.row.stockDate ? dayjs(scope.row.stockDate).format('YYYY-MM-DD HH:mm:ss') : '--' }}
        </template>
      </el-table-column>

      <el-table-column label="状态" width="90" fixed="right">
        <template #default="scope">
          <el-tag class="ml-2" type="danger" v-if="scope.row.status == 0">
            待付款</el-tag>
          <el-tag class="ml-2" type="success" v-if="scope.row.status == 1">
            托管中</el-tag>
          <el-tag class="ml-2" type="info" v-if="scope.row.status == 2">
            已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="230" fixed="right">
        <template #default="scope">
          <el-button size="small" type="primary" v-if="scope.row.status == 1" @click="addUser(1, scope.row)">
            修改售卖数 </el-button>
          <!-- <el-button size="small" type="primary" v-if="scope.row.status == 1" @click="addUser(2, scope.row)">
            修改支付数 </el-button> -->
        </template>
      </el-table-column>
    </el-table>
  </YsPagination>
  <el-dialog v-model="dialogFormVisible" :title="type == 1 ? '修改售卖数量' : '修改支付数量'" center>
    <el-form :model="addForm" :rules="rules" ref="ruleFormRef" status-icon>
      <el-form-item label="订单编码" :label-width="formLabelWidth">
        <el-input v-model="addForm.refId" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="托管人" :label-width="formLabelWidth">
        <el-input v-model="addForm.uname" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="托管商品" :label-width="formLabelWidth">
        <el-input v-model="addForm.goodsName" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="商品价格" :label-width="formLabelWidth">
        <el-input v-model="addForm.price" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="托管数量" :label-width="formLabelWidth">
        <el-input v-model="addForm.stockCount" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="已售数量" :label-width="formLabelWidth">
        <el-input v-model="addForm.soldCount" disabled autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="已付数量" :label-width="formLabelWidth">
        <el-input v-model="addForm.settledCount" disabled autocomplete="off" clearable />
      </el-form-item>


      <el-divider content-position="left">本次修改</el-divider>
      <el-form-item label="现售数量" :label-width="formLabelWidth" v-if="type == 1" prop="mysoldCount">
        <el-input-number v-model="addForm.mysoldCount" :min="1" :max="10000000" />
      </el-form-item>
      <el-form-item label="现付数量" :label-width="formLabelWidth" v-if="type == 2" prop="mysettledCount">
        <el-input-number v-model="addForm.mysettledCount" :min="1" :max="10000000" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">关闭</el-button>
        <el-button type="primary" @click="handleSubmit">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { trusteeshipListApi, trusteeshipSoldApi, trusteeshipsettledApi } from '@/api/trusteeship.js'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      status:'',
      type: 1,
      dayjs,
      total: 0,
      uname: '',
      userlist: [],
      dialogFormVisible: false,
      addForm: {
        mysoldCount: 0,
        mysettledCount: 0
      },
      formLabelWidth: '140px',
      rules: {
        mysoldCount: [
          { required: true, message: '请输入现售数量', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (this.addForm.stockCount < (Number(this.addForm.soldCount) + Number(value))) {
                callback(new Error('售卖数量不能超过托管总数'));
              } else {
                callback()
              }
            }, trigger: 'blur'
          },
        ],
        mysettledCount: [
          { required: true, message: '请输入现付数量', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (this.addForm.stockCount < (Number(this.addForm.settledCount) + Number(value))) {
                callback(new Error('支付数量不能超过托管总数'));
              } else {
                callback()
              }
            }, trigger: 'blur'
          },
        ],
      }
    }
  },

  methods: {
    query() {
      this.$refs.node.pageNo = 1;
      this.$refs.node.queryFunc()
    },
    async getList(obj) {
      let res = await trusteeshipListApi({ ...obj, uname: this.uname ,status:this.status})
      if (res.meta.code == 0) {
        this.userlist = res.data.rows
        this.total = res.data.count
      }
    },
    addUser(type, data) {
      this.type = type
      this.addForm = { ...this.addForm, ...JSON.parse(JSON.stringify(data)) }
      this.dialogFormVisible = true
    },
    handleSubmit() {
      this.$refs.ruleFormRef.validate(async (valid, fields) => {
        if (valid) {
          if (this.type == 1) {
            let params = {
              id: this.addForm.id,
              soldCount: this.addForm.mysoldCount,
            }
            let res = await trusteeshipSoldApi(params)
            if (res.meta.code == 0) {
              this.dialogFormVisible = false
              this.$refs.node.queryFunc()
              ElMessage({
                type: 'success',
                message: `修改成功！`,
              })
            }
          } else {
            let params = {
              id: this.addForm.id,
              settledCount: this.addForm.mysettledCount
            }
            let res = await trusteeshipsettledApi(params)
            if (res.meta.code == 0) {
              this.dialogFormVisible = false
              this.$refs.node.queryFunc()
              ElMessage({
                type: 'success',
                message: `修改成功！`,
              })
            }
          }


        } else {
          console.log('error submit!', fields)
        }
      })
    },

  },
}
</script>

<style lang='less' scoped>
.content-box {
  margin: 20px;
  background-color: #ffffff;
}
</style>
