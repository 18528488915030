/**
 * @description 封装订单相关的接口
 */

import Ajax from "@/utils/request.js";
import {TRUSTEESHIP_URL} from "@/config/user.cfg";




/** 后台管理托管列表接口 */
export function trusteeshipListApi(params) {
    return Ajax({
        url: TRUSTEESHIP_URL,
        method: 'GET',
        params
    })
}


/** 修改售卖的数量 */

export function trusteeshipSoldApi(data) {
    return Ajax({
        url: TRUSTEESHIP_URL+'/sold',
        method: 'PUT',
        data
    })
}
/** 修改支付数量 */

export function trusteeshipsettledApi(data) {
    return Ajax({
        url: TRUSTEESHIP_URL+'/settled',
        method: 'PUT',
        data
    })
}


// 修改托管数量是两个接口
// put('/trusteeship/sold'
// 参数：{
//     id: { type: "int", min: 0 },
//     soldCount: { type: "int", min: 0 }
// }
// put('/trusteeship/settled'
// 参数：{
//     id: { type: "int", min: 0 },
//     settledCount: { type: "int", min: 0 }
// }